import React from "react";
import {withTrans} from "../../i18n/withTrans";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import {AiOutlineDown} from "react-icons/ai";
import ListIems from "./ListIems";

const Collapse = ({t, title, body, list, data}) => {

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<AiOutlineDown />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <h4 className="cart-title"><span>{t(`rgpd.${title}`)}</span> </h4>
            </AccordionSummary>
            <AccordionDetails>
                <p style={{whiteSpace: "pre-line"}}>{t(`rgpd.${body}`)}</p>
                {list && (
                    <ListIems data={data} type='protectDataCSub' />
                )}
            </AccordionDetails>
        </Accordion>
    )
};

export default withTrans(Collapse)
