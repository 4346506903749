import React from 'react';
import PropTypes from 'prop-types';
import { withTrans } from '../../i18n/withTrans';
import { List, ListItem } from '@material-ui/core';

const ListItems = ({ t, data, type }) => {
  return (
    <List>
      {data.map((item) => (
        <ListItem key={item}>{t(`rgpd.${type}.${item}`)}</ListItem>
      ))}
    </List>
  );
};

export default withTrans(ListItems);

ListItems.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired
};
